.termini {

  &-wrapper {
    width: 40%;
    margin: 0 auto;
  }

}

.terminiAccept {

  &-wrapper {
    width: 60%;
    margin: 0 auto;
  }
}