.input-wrapper {
  position: relative;
}

.input-component {
  width: 100%;
  height: 51px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 20px;
  border: none;
  color: $black;

  &::placeholder {
    color: white;
  }

  &:focus-visible {
    outline: none;
  }
}

.input-hint {
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  margin-top: 5px;
  color: white;
  text-align: right;
  margin-bottom: 4px;
}

.input-icon {
  position: absolute;
  top: 20%;
  right: 10px;
  cursor: pointer;
}

.inputCheck {
  &-wrapper {
    width: 100%;
    height: 51px;
    background: $white;
    border: 1px solid $grayLight;
    border-radius: 8px;
    padding: 3px 10px;
    margin-top: 8px;
  }

  &-component {
    border: none;
    color: $primary;
    width: 100%;

    &::placeholder {
      color: $primaryLightShadow;
    }

    &:focus-visible {
      outline: none;
    }
  }

  &-description {
    font-size: 10px;
    font-weight: $semi-bold;
    text-transform: uppercase;
    color: $gray;
    margin-bottom: 2px;
  }
}

.textArea {
  width: 100%;
  background: $white;
  border: 1px solid $grayLight;
  border-radius: 8px;
  padding: 3px 10px;
  margin-top: 8px;
  

  textarea {
    min-height: 120px;
  }
}
