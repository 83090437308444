.productVideo {
  height: 300px;
  cursor: pointer;

  &-image {
    height: 75%;
  }

  &-info {
    height: 25%;
    padding: 15px;

    &-heading {
      font-size: 14px;
      font-weight: $medium;
      color: $black;
      margin-bottom: 5px;
    }

    &-subheading {
      font-size: 10px;
      color: $gray;
      font-weight: $regular;
      margin-right: 10px;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &-price {
      font-size: 20px;
      font-weight: $medium;
      color: $black;
      margin-bottom: 0;
      text-align: right;
    }

    &-firstPrice {
      font-size: 10px;
      font-weight: $semi-bold;
      color: $red;
      text-align: right;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}