.customDish {
  &-wrapper {
    width: 50%;
    margin: 0 auto;
    
    @include respond(L) {
      width: 80%;
    }
  
    @include respond(M) {
      width: 90%;
    }  
  }

}

.Dropdown-control {
  width: 100%;
  height: 51px;
  background: $white;
  border: 1px solid $grayLight !important;
  border-radius: 8px !important;
  padding: 13px !important;
  margin-top: 8px;
}

.Dropdown-placeholder {
  color: $primary;
}

.Dropdown-menu {
  border: 1px solid $grayLight !important;
  border-radius: 8px !important;
}

.Dropdown-option {

  &:hover {
    background-color: $primaryLightShadow !important;
  }
}

.Dropdown-arrow {
  top: 23px !important;
}
