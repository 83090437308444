.progress-wrapper {
  width: 110px;
  margin-right: 20px;
  margin-left: 20px;

  @include respond(M) {
    margin-right: 10px;
    margin-left: 10px;
  }
}

.progress-details {
  margin-top: 10px;
}

.macro-name {
  font-size: 12px;
  font-weight: $bold;
  text-transform: uppercase;
  color: $gray;
  margin-bottom: 3px;
}

.consigliati {
  font-weight: $medium;
  font-size: 12px;
  color: $black;
}

.macros-items {
  font-size: 12px;
  font-weight: $medium;
  color: $gray;
  margin-bottom: 5px;
  margin-left: 15px;
}

.refeed-text {
  font-size: 18px;
  font-weight: $medium;
  color: $red;
  margin-left: 20px;
}

.date-string {
  margin-bottom: 0;

  &-day {
    font-size: 28px;
    color: $primary;
  }

  &-month {
    font-size: 18px;
    color: $black;
  }
}

.menu-label {
  background-color: $primary;
  padding: 3px 12px;
  border-radius: 8px;

  &-text {
    color: white;
    margin-bottom: 0;
    font-size: 12;
  }
}

.calendar-wrapper {
  margin-top: 20px;
}

//// CALENDAR STYLE
.react-calendar {
  border: none !important;
  font-family: $main-font !important;
}

.react-calendar__navigation button {
  font-weight: $regular !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: $primaryLightShadow !important;
}

.react-calendar__month-view__weekdays__weekday {
  font-weight: $regular !important;
  text-decoration-line: none !important;
  text-decoration: none !important;
  color: $primary !important;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  font-weight: $regular !important;
}

.react-calendar__month-view__days__day {
  color: $grayDark !important;
  border-radius: 100px !important;
}

.react-calendar__tile--active {
  background: $primaryLight !important;
  color: white !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: $primaryLight !important;
  color: white !important;
}

.react-calendar__tile--now {
  background: white !important;
  color: $primary !important;
}

abbr[title] {
  text-decoration: none !important;
  cursor: default !important;
}
