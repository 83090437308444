.allenamenti {
  &-sessione-container {
    text-align: center;
    cursor: pointer;

    &_active {
      text-align: center;
      border-bottom: 2px solid $primary;
      cursor: pointer;
    }
  }

  &-sessione-text {
    font-weight: $semi-bold;
    color: $gray;

    &_active {
      font-weight: $semi-bold;
      color: $primary;
    }
  }

  &-content-wrapper {
    width: 70%;
    margin: 0 auto;

    @include respond(L) {
      width: 90%;
    }
  }

  &-items {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 5.46128px 20.4798px rgba(181, 181, 181, 0.25);
    border-radius: 8px;
    width: 100px;
    padding: 10px;
    text-align: center;
    margin-right: 8px;

    &-text {
      font-size: 10px;
      font-weight: $bold;
      color: $black;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &-content {
      font-size: 14px;
      font-weight: $semi-bold;
      color: $primary;
      margin-bottom: 0;
    }
  }

  &-video-container {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
  }

  &-pag {
    width: 35px;
    height: 35px;
    background-color: $white;
    border: 1px solid $primaryLightShadow;
    border-radius: 50%;
    text-align: center;
    padding-top: 5px;

    &_active {
      background-color: $primary;
    }
  }

  &-pag-text {
    font-weight: $primary;
    color: $primary;
    font-size: 14px;

    &_active {
      color: $white;
    }
  }

  &-pag-ex {
    font-size: 12px;
    font-weight: $medium;
    color: $gray;
    margin-bottom: 0;
    margin-left: 5px;

    &_active {
      color: $primaryLight;
    }
  }

  &-pag-span {
    width: 100%;
    height: 2px;
    background-color: $primary;
  }

  &-video {
    &-intro {
      padding: 5px;
    }

    &-text {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: $regular;
      color: $primary;
      margin-right: 5px;
    }
  }

  &-saveButton {
    font-size: 14px;
    color: $primary;
    padding: 5px;
    border: 1px solid $primary;
    border-radius: 8px;
    margin-bottom: 0;
    cursor: pointer;
  }

  &-noteInput {
    border: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
}
