.customIngredient {

  &-wrapper {
    width: 50%;
    margin: 0 auto;

    @include respond(L) {
      width: 80%;
    }
  
    @include respond(M) {
      width: 90%;
    }  
  }
}